const isDev = true; // Use to display some events in console

// Accordion is used to toggle accordion items in filter lists
// use it like the following example:
// accordion.open(accordionEl)
const accordion = {
    open: (accordion) => {
        const trigger = accordion.querySelector(".toggle-trigger");
        const svg = trigger.querySelector("svg");
        accordion.classList.add("active");
        svg?.classList.add("active");
    },
    close: (accordion) => {
        const trigger = accordion.querySelector(".toggle-trigger");
        const svg = trigger.querySelector("svg");
        accordion.classList.remove("active");
        svg?.classList.remove("active");
    },
    toggle: (accordion) => {
        const trigger = accordion.querySelector(".toggle-trigger");
        const svg = trigger.querySelector("svg");
        accordion.classList.toggle("active");
        svg?.classList.toggle("active");
    },
};

let global = {
    results: null,
};

// Reset All Filters
const resetAllFilters = ({
                             searchFilterContainer,
                             filterWrapper,
                             child = null,
                             searchString = "",
                             storageName,
                         }) => {
    isDev && !child && console.log("resetAllFilters");

    // remove session storage
    sessionStorage.removeItem(storageName);

    // remove url parameters
    const url = window.location.href.replace(window.location.search, "");
    window.history.replaceState({}, "", url);

    // Find first radio button and check it
    let filterBoxes = null;

    if (child) {
        filterBoxes = child.querySelectorAll(".filter-inner-category-filter");
    } else {
        filterBoxes = document.querySelectorAll(".filter-inner-category");
    }

    // Reset all inputs of all group (enable first input of a group)
    filterBoxes.forEach((box, i) => {
        const filterItems = box.querySelectorAll(
            ".filter-inner-category-filter-item"
        );
        const firstFilterItemInput = filterItems[0].querySelector("input");
        const type = firstFilterItemInput.type;

        filterItems.forEach((item, i_) => {
            const input = item.querySelector("input");
            input.checked = i + i_ === 0 && !child ? true : false;
        });

        document.querySelector(".js-active-filters").innerHTML = "";

        // Handle nested/children items
        const children = box.querySelectorAll(
            ".filter-inner-category-filter-item-toggle-content"
        );

        if (children.length > 0) {
            children.forEach((child) => {
                resetAllFilters({
                    searchFilterContainer: searchFilterContainer,
                    filterWrapper: filterWrapper,
                    storageName: storageName,
                    child: child,
                });
            });
        }

        !child && resultsHandler({
            searchFilterContainer: searchFilterContainer,
            showAll: true,
            searchString: searchString,
        });
        !child && filterBoxesHandler(filterWrapper);
    });
};

// Set Url Parameters
const setUrlParameter = (parameterObject) => {
    // const urlParams = new URLSearchParams(window.location.search);
    const urlParams = new URLSearchParams();
    for (const [key, value] of Object.entries(parameterObject)) {
        if (value) {
            urlParams.set(key, value);
        }
    }
    const newRelativeUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, "", newRelativeUrl);
};

// Get Active Filters
// Parse the filter inputs for checked items
const getActiveFilters = (filterWrapper) => {
    if (!filterWrapper) {
        console.error('filterWrapper is not defined or invalid');
        return {};
    }

    let filterObject = {};
    const inputs = filterWrapper.querySelectorAll(".filter-inner-category-filter-item input:checked");

    inputs.forEach((input) => {
        // Radios
        if (input.type === "radio") {
            filterObject[input.getAttribute("name")] = input.checked && input.getAttribute("data-name");
        }

        // Checkboxes
        if (input.type === "checkbox") {
            const filter = filterObject[input.getAttribute("name")];
            const filterArray = filter ? [...filterObject[input.getAttribute("name")]] : [];

            filterObject[input.getAttribute("name")] = input.checked
                ? [...filterArray, input.getAttribute("data-name")]
                : filterArray.filter((item) => item !== input.getAttribute("data-name"));
        }
    });

    return filterObject;
};

// Filter results
// Show or hide result items depending on filterObject
const resultsHandler = ({ filterObject, searchFilterContainer, showAll, searchString }) => {
    const items = searchFilterContainer.querySelectorAll(".content-inner-item");
    let countItems = 0;

    // Check if search term exists (i.e., has more than 0 characters)
    const isSearchTermRelevant = searchString && searchString.length > 0;

    items.forEach((item) => {
        const itemFilters = item.getAttribute("data-filter").split(";");
        const itemText = item.textContent.toLowerCase();

        if(!filterObject) {
            countItems++;
            item.style.display = "block";
            return;
        }

        // Check if the item matches the active filters
        const filterMatch = Object.values(filterObject).every((val) => itemFilters.includes(val));

        // Only apply search match if search term is relevant
        const searchMatch = !isSearchTermRelevant || itemText.includes(searchString.toLowerCase());

        // Display item if it matches both filters and search term (if applicable)
        if (filterMatch && searchMatch) {
            countItems++;
            item.style.display = "block";
        } else {
            item.style.display = "none";
        }
    });

    // Handle the "no results" case
    const noResultsWrapper = searchFilterContainer.querySelector("#no-results");
    noResultsWrapper.style.display = countItems < 1 ? "block" : "none";

    // Update the apply button count
    searchFilterContainer.querySelectorAll(".fc__apply-button").forEach((button) => {
        const number = button.querySelector(".counter__number");
        const count = showAll ? items.length : countItems;
        number.innerHTML = `${count}`;
        button.disabled = count < 1;
    });
};

// Filter Boxes
// Set filter inputs to be checked/unchecked depending on filterObject
// Show or hide accordion items
const filterBoxesHandler = (filterWrapper, filterObject) => {
    const filterBoxes = filterWrapper.querySelectorAll(".filter-inner");

    // Reset all child inputs if parent input gets unchecked
    filterBoxes.forEach((box, i) => {
        const filterItems = box.querySelectorAll(".filter-inner-category-filter");
        filterItems.forEach((item) => {
            const trigger = item.querySelector(".toggle-trigger");
            const toggle = item.querySelector(
                ".filter-inner-category-filter-item-toggle-content"
            );

            if (trigger && toggle) {
                const input = trigger.querySelector("input");

                if (input) {
                    if (!input.checked) {
                        toggle.querySelectorAll("input").forEach((input) => {
                            input.checked = false;
                        });
                        accordion.close(item);
                    } else {
                        accordion.open(item);
                    }
                } else if (!input) {
                    trigger.style.cursor = "pointer";

                    trigger?.addEventListener("click", () => {
                        if (toggle.style.display === "none") {
                            accordion.open(item);
                        } else {
                            accordion.close(item);
                        }
                    });

                    // Open groups which dont have an input
                    const checkedInputs = toggle.querySelectorAll("input:checked");
                    if (checkedInputs.length > 0) {
                        accordion.open(item);
                    }
                }
            }
        });

        const inputs = box.querySelectorAll(
            ".filter-inner-category-filter-item input"
        );

        // Toggle filter groups
        i === 0 &&
        inputs.length > 0 &&
        inputs.forEach((input) => {
            const groupName = input.getAttribute("data-toggle");
            const groupEl = filterWrapper.querySelector(
                `[data-filter-group="${groupName}"]`
            );

            if (groupEl) {
                if (input.checked) {
                    // Open filter group
                    groupEl.style.display = "block";

                    // Check first input
                    // if no input is checked in group
                    if (!groupEl.querySelector("input:checked")) {
                        const input = groupEl.querySelector("input");

                        if (input.type === "radio") {
                            input.checked = true;
                        }
                    }
                } else {
                    // Close filter group
                    // uncheck all inputs in group
                    groupEl.style.display = "none";
                    groupEl.querySelectorAll("input").forEach((input) => {
                        input.checked = false;
                    });
                }
            }
        });
    });
};

const flatObject = (object) => {
    return Object.values(object).flatMap((value) =>
        Array.isArray(value) || typeof value === "object"
            ? Object.values(value)
            : value
    );
};

const getUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const urlParamsObject = {};
    for (const [key, value] of urlParams.entries()) {
        urlParamsObject[key] = value;
    }

    if (
        Object.keys(urlParamsObject).length === 0 &&
        urlParamsObject.constructor === Object
    ) {
        return null;
    }

    return urlParamsObject;
};

const checkInputsByParams = (filterWrapper, urlParams) => {
    const inputs = filterWrapper.querySelectorAll("input");
    inputs.forEach((input) => {
        const group = input.getAttribute("name");
        const name = input.getAttribute("data-name");

        if (typeof urlParams[group] === "object") {
            if (Object.values(urlParams[group]).includes(name)) {
                input.checked = true;
            }
        } else if (Array.isArray(urlParams[group])) {
            if (urlParams[group].includes(name)) {
                input.checked = true;
            }
        } else if (typeof urlParams[group] === "string") {
            if (urlParams[group].includes(name)) {
                input.checked = true;
            }
        }
    });
};

const findAncestor = (el, cls) => {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
};

// Initialize event handler for newly created filter tag
const initFilterTag = (tag, filterWrapper) => {
    const removeButton = tag.querySelector(".remove-filter");

    removeButton.addEventListener("click", (event) => {
        const groupName = removeButton.getAttribute("data-group");
        const name = removeButton.getAttribute("data-name");
        const input = filterWrapper.querySelector(
            `input[name="${groupName}"][data-name="${name}"]`
        );
        if (input?.type === "checkbox") {
            input.click();
        } else if (input?.type === "radio") {
            const firstFilter = filterWrapper.querySelector(
                `input[name="${groupName}"]`
            );
            firstFilter?.click();
        }

        tag.remove();
    });
};

// Filter tags
// Defined to be visible on mobile devices only
const handleFilterTags = (filterWrapper, filterObject) => {
    const filterTagWrapper = filterWrapper.querySelector(".js-active-filters");

    filterTagWrapper.innerHTML = "";

    Object.keys(filterObject).forEach((key, value) => {
        const groupName = key;
        let names = filterObject[key];

        if (typeof names === "string") {
            names = [names];
        }

        const groupInputs = filterWrapper.querySelectorAll(
            `input[name="${groupName}"]`
        );

        names.forEach((name) => {
            const input = Array.from(groupInputs).find(
                (inputEl) => inputEl.getAttribute("data-name") === name
            );
            const isDefault = input?.getAttribute("data-default");

            if (!isDefault) {
                const label = findAncestor(
                    input,
                    "filter-inner-category-filter-item"
                ).querySelector("label").innerHTML;
                isDefault && isDev && console.log("isDefault: ", label);

                // Create new filter item
                const filterItem = document.createElement("div");
                filterItem.classList.add("active-filter-item");
                filterItem.innerHTML = `
            <button class="remove-filter" data-name="${name}" data-group="${groupName}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                </svg>
            </button>
            <div class="label">${label}</div>
        `;
                initFilterTag(filterItem, filterWrapper);

                filterTagWrapper.append(filterItem);
            }
        });
    });
};

// 02 - Define filter source (url and session storage or by change event)
// And handle results, update url and session storage, filter tags
const handleFilters = ({
                           searchFilterContainer,
                           filterWrapper,
                           searchString = "",
                           init = false,
                           useUrlParams = true,
                           resetButton,
                           storageName,
                       }) => {
    if (!searchFilterContainer || !filterWrapper) {
        console.error('searchFilterContainer or filterWrapper is not defined');
        return;
    }

    const urlParams = getUrlParams();

    const getStorageFilters = storageName && sessionStorage.getItem(storageName);
    const storageFilterObject = getStorageFilters ? JSON.parse(getStorageFilters) : null;

    let tempFilterObj = {};

    if (init === true) {
        // Initial filter setup
        if (urlParams && useUrlParams) {
            tempFilterObj = urlParams;
            sessionStorage.setItem(storageName, JSON.stringify(tempFilterObj));
        } else if (storageFilterObject) {
            tempFilterObj = storageFilterObject;
            useUrlParams && setUrlParameter(tempFilterObj);
        }

        checkInputsByParams(filterWrapper, tempFilterObj);
        filterBoxesHandler(filterWrapper, tempFilterObj);
    } else {
        // Filter changes
        filterBoxesHandler(filterWrapper, getActiveFilters(filterWrapper));
        tempFilterObj = getActiveFilters(filterWrapper);
        isDev && console.log("filters have changed: ", tempFilterObj);
        useUrlParams && setUrlParameter(tempFilterObj);
        sessionStorage.setItem(storageName, JSON.stringify(tempFilterObj));
    }

    resultsHandler({
        filterObject: flatObject(tempFilterObj),
        searchFilterContainer: searchFilterContainer,
        searchString: searchString,  // Pass the search string here
    });
    handleFilterTags(filterWrapper, tempFilterObj);

    if(resetButton) {
        if (
            tempFilterObj &&
            Object.keys(tempFilterObj)?.length < 2 &&
            Object.values(tempFilterObj)[0]?.includes("all")
        ) {
            resetButton.disabled = true;
        } else {
            resetButton.disabled = false;
        }
    }
};

// 01 - Initialize filters on page load
// Define variables and event listeners
const initFilters = () => {
    console.log("Initializing filters...");
    const searchFilterContainers = document.querySelectorAll(".search-filter-container");

    if (!searchFilterContainers.length) {
        console.error("No searchFilterContainers found.");
        return;
    }

    searchFilterContainers?.forEach((searchFilterContainer) => {
        const filterWrapper = searchFilterContainer.querySelector(".filter-container");
        const resetButton = searchFilterContainer.querySelector("#reset-filter");

        if (!filterWrapper || !resetButton) {
            console.error('filterWrapper or resetButton is missing');
            return;
        }

        const id = searchFilterContainer.getAttribute("data-filter-id");
        const lang = searchFilterContainer.getAttribute("data-filter-lang");
        const storageName = "flmro-filter_" + lang + "_" + id;

        const filterArgs = {
            searchFilterContainer: searchFilterContainer,
            filterWrapper: filterWrapper,
            useUrlParams: false,
            resetButton: resetButton,
            storageName: storageName,
        };

        resetButton.addEventListener("click", () => {
            resetAllFilters({
                searchFilterContainer: searchFilterContainer,
                filterWrapper: filterWrapper,
                storageName: storageName,
                child: null,
            });

            // Re-run the resultsHandler without filters but with the search term
            resultsHandler({
                filterObject: {},  // Reset filters
                searchFilterContainer: document.querySelector('.search-filter-container'),
            });

            resetButton.disabled = true;
        });

        handleFilters({
            ...filterArgs,
            init: true,
        });

        filterWrapper.addEventListener("change", () => {
            // Retrieve the current search term from the input field
            const searchTerm = document.querySelector("#content-header-search").value.toLowerCase();

            // Get active filters
            const activeFilters = getActiveFilters(filterWrapper);

            // Apply both filters and the search term in the resultsHandler
            handleFilters({
                searchFilterContainer: document.querySelector('.search-filter-container'),
                filterWrapper: document.querySelector('.filter-container'),
                searchString: searchTerm,  // Reapply the search term
                filterObject: activeFilters,  // Apply the filters
            });

            // Force the search term to trigger without needing user input
            if (searchTerm.length > 0) {
                inputField.trigger("input"); // Simulate the search term being typed again
            }

            const resetButton = document.querySelector("#reset-filter");
            if (resetButton && Object.keys(activeFilters).length > 0) {
                resetButton.disabled = false;  // Enable the reset button
            }
        });
    });
};

// Filter item toggle
// Only used for the small chevron/arrow icon in accordion items
const filterItemToggle = () => {
    const triggers = document.querySelectorAll(".filter-item__toggle");

    triggers.forEach((trigger) => {
        const parent = findAncestor(trigger, "filter-inner-category-filter");

        trigger.addEventListener("click", () => {

            if(parent.classList.contains('active')) {
                parent.classList.remove('active');
            } else {
                parent.classList.add('active');
            }

            const input = parent.querySelector(".toggle-trigger input");

            /*if (input) {
              if (input.checked) {
                parent.classList.toggle("active");
              }
            } else {
              parent.classList.toggle("active");
            }*/
        });
    });
};

initFilters();
filterItemToggle();

jQuery(document).ready(function ($) {
    const inputField = $("#content-header-search");
    const iconClose = $(".content-header-search").find(".reset-string-search");

    // Function to check if the current site is the German site
    const isGermanSite = () => {
        // Check if the URL path contains /de/
        return window.location.pathname.startsWith("/de/");
    };

    // Cancel search
    iconClose.on("click", function () {
        inputField.val("");
        const activeFilters = getActiveFilters(document.querySelector('.filter-container'));

        resultsHandler({
            filterObject: activeFilters,  // Keep the current filters
            searchFilterContainer: document.querySelector('.search-filter-container'),
            searchString: "",
        });

        $("#no-results").hide();
        iconClose.hide();
        inputField.focus();
    });

    // Text search
    inputField.on("input", function () {
        if ($(this).val().length > 0) {
            iconClose.show();
        } else {
            iconClose.hide();
        }

        const search = $(this).val().toLowerCase();  // Get search term
        const activeFilters = getActiveFilters(document.querySelector('.filter-container')); // Get current filters

        handleFilters({
            searchFilterContainer: document.querySelector('.search-filter-container'),
            filterWrapper: document.querySelector('.filter-container'),
            searchString: search,  // Pass the search term
            filterObject: activeFilters  // Pass the active filters
        });

        if (isGermanSite()) {
            toggleTenderTextsVisibility(activeFilters);
        } else {
            $("#results-tender_texts").hide();
            if ($(".content-inner-item:visible").length === 0) {
                $("#no-results").show();
            } else {
                $("#no-results").hide();
            }
        }
    });

    const toggleTenderTextsVisibility = (activeFilters) => {
        const visibleItems = $(".content-inner-item:visible").length;

        if (activeFilters && Object.values(activeFilters).flat().includes("type_tender_texts")) {
            if (visibleItems === 0) {
                $("#results-tender_texts").show();
                $("#no-results").hide();
            } else {
                $("#results-tender_texts").hide();
                $("#no-results").hide();
            }
        } else {
            $("#results-tender_texts").hide();
            if (visibleItems === 0) {
                $("#no-results").show();
            } else {
                $("#no-results").hide();
            }
        }
    };

    // Check the filter on page load
    const activeFilters = getActiveFilters(document.querySelector('.filter-container'));

    if (isGermanSite()) {
        toggleTenderTextsVisibility(activeFilters);
    } else {
        $("#results-tender_texts").hide();
        if ($(".content-inner-item:visible").length === 0) {
            $("#no-results").show();
        } else {
            $("#no-results").hide();
        }
    }

    // Monitor changes in the filter selection
    $('.filter-container').on('change', function () {
        const activeFilters = getActiveFilters(document.querySelector('.filter-container'));

        if (isGermanSite()) {
            toggleTenderTextsVisibility(activeFilters);
        } else {
            $("#results-tender_texts").hide();
            if ($(".content-inner-item:visible").length === 0) {
                $("#no-results").show();
            } else {
                $("#no-results").hide();
            }
        }
    });
});