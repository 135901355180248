// toggle #mobile-menu on click on #mobile-nav-open
$('#mobile-nav-open').click(function() {
    event.preventDefault();
    $('#mobile-menu').slideToggle();
});

// close on #mobile-menu-close
$('#mobile-menu-close').click(function() {
    event.preventDefault();
    $('#mobile-menu').slideToggle();
});

// on click on .mobile-main-menu a.hast-sub-menu prevent default and toggle the sub menu with the same data-id
$('.mobile-main-menu a.has-sub-menu').click(function() {
    event.preventDefault();

    var subMenu = $('.mobile-sub-menu[data-parent="' + $(this).data('id') + '"]');
    var mainMenu = $(this).parent();
    var back = $('#mobile-menu-back');

    back.toggleClass('open');
    mainMenu.toggleClass('open');
    subMenu.toggleClass('open');
});

// on click on #mobile-menu-back toggle the sub menu with the same data-id
$('#mobile-menu-back').click(function() {
    event.preventDefault();

    var subMenu = $('.mobile-sub-menu.open');
    var mainMenu = $('.mobile-main-menu.open');
    var back = $('#mobile-menu-back');

    back.toggleClass('open');
    mainMenu.toggleClass('open');
    subMenu.toggleClass('open');
});

// make dropdown out of #markt-switch 
$('.markt-switch').click(function() {
    $(this).children('.markt-dropdown').slideToggle();
});