jQuery(document).ready(function ($) {
  var blogId = $("#blog-id").val();

  $(".add-to-cart").click(function (e) {
    e.preventDefault();
    var name = $(this).data("name");
    var url = $(this).data("url");
    var filename = $(this).data("filename");
    var cart = JSON.parse(localStorage.getItem("download_cart" + blogId)) || [];
    var index = -1;
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].name === name && cart[i].url === url) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      cart.splice(index, 1);
      // console.log(name + ' removed from cart');
      $(this).find(".add").show();
      $(this).find(".remove").hide();
    } else {
      cart.push({ name: name, url: url, filename: filename });
      // console.log(name + ' added to cart');
      $(this).find(".add").hide();
      $(this).find(".remove").show();
    }
    localStorage.setItem("download_cart" + blogId, JSON.stringify(cart));
    cartCount();
  });

  // if item is already in cart, show remove button
  var cart = JSON.parse(localStorage.getItem("download_cart" + blogId)) || [];
  for (var i = 0; i < cart.length; i++) {
    $(
      '.add-to-cart[data-url="' +
        cart[i].url +
        '"]'
    )
      .find(".add")
      .hide();
    $(
      '.add-to-cart[data-url="' +
        cart[i].url +
        '"]'
    )
      .find(".remove")
      .show();
  }

  // add function to show a little bubble with the number of items in the cart on the cart icon in the header menu
  // as function
  function cartCount() {
    var cart = JSON.parse(localStorage.getItem("download_cart" + blogId)) || [];
    var cartCount = cart.length;
    if (cartCount > 0) {
      $(".cart-count").text(cartCount);
      $(".cart-count").show();
    } else {
      $(".cart-count").hide();
    }
  }
  cartCount();
});
