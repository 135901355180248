jQuery(document).ready(function ($) {
  $(".ui.search input").on("input", function () {
    // if no search term, clear results
    if ($(this).val().length === 0) {
      $(".results").html("");
      return;
    }
    // search term
    var searchTerm = $(this).val().toLowerCase();
    // get all products from array contentSearch
    var products = contentSearch;
    // filter products
    var filteredProducts = products.filter(function (product) {
      return product.title.toLowerCase().includes(searchTerm);
    });

    // render products
    var html = "";
    filteredProducts.forEach(function (product) {
      if (
        window.location.href.indexOf("/de/") > -1 ||
        window.location.href.indexOf("/at-ch/") > -1
      ) {
        var typeName = product.type;
      } else {
        var typeName = product.type_en;
      }
      html +=
        '<a href="' +
        product.link_url +
        '" class="result" data-type="' +
        product.type +
        '">';

      html += '<div class="image">';
      html += '<img src="' + product.image + '">';
      html += "</div>";

      html += '<div class="content">';
      html += '<div class="type">' + typeName + "</div>";
      html += '<div class="title">' + product.title + "</div>";
      html += '<div class="description">' + product.category + "</div>";
      html += "</div>";
      html += "</a>";
    });
    $(".results").html(html);

    if (filteredProducts.length === 0) {
      if (
        window.location.href.indexOf("/de/") > -1 ||
        window.location.href.indexOf("/at-ch/") > -1
      ) {
        $(".results").html(
          '<div style="padding: 20px;" class="result"><p><strong>Wir konnten keine Produkte oder Systeme finden.</strong></p></div>'
        );
      } else {
        $(".results").html(
          '<div style="padding: 20px;" class="result"><p><strong>Sorry, no products or applications found.</strong></p></div>'
        );
      }
    }
  });

  // close search results on click outside
  $(document).on("click", function (e) {
    if (!$(e.target).closest(".ui.search").length) {
      $(".results").html("");
      $(".ui.search input").val("");
    }
  });
});
