jQuery(document).ready(function($) {
    var blogId = $('#blog-id').val();

    // Download cart button
    $('#download-all').click(function(e) {
        e.preventDefault();
        generateZIP();
    });

    // remove all items from cart and reload page
    $('#remove-all').click(function(e) {
        e.preventDefault();
        localStorage.removeItem('download_cart' + blogId);
        location.reload();
    });


    // Download cart function
    function generateZIP() {

        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Flamro_Downloads.zip";
        var downloads = JSON.parse(localStorage.getItem('download_cart' + blogId));

        // console.log(downloads);
    
        for (let downloadObj in downloads) {
            let linkValue = downloads[downloadObj];
    
            let filename = linkValue.url;
            filename = filename.substring(filename.lastIndexOf('/') + 1);

            if (linkValue.filename) {
                filename = linkValue.filename;
            }

            let url = linkValue.url;
    
            JSZipUtils.getBinaryContent(url, function (err, data) {
    
                if (err) { throw err; }
    
                zip.file(filename, data, { binary: true });
                count++;
    
                if (count == downloads.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, zipFilename);
                    });
                }
    
            });
    
        }




        // clear cart
        localStorage.removeItem('download_cart' + blogId);
        $('.add').show();
        $('.remove').hide();
    }

});