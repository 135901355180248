jQuery(document).ready(function($) {
    var basename = function(path) {
        return path.split('/').reverse()[0];
    }

    var blogId = $('#blog-id').val();
    var cart = JSON.parse(localStorage.getItem('download_cart' + blogId)) || [];
    for (var i = 0; i < cart.length; i++) {
        var name = cart[i].name;
        var url = cart[i].url;
        var filename = basename(cart[i].url);
        if (cart[i].filename && String(cart[i].filename).length !== 0) {
            filename = cart[i].filename;
        }

        var item =  '<div class="content-inner-item" data-filter="sicherheitsdatenblaetter;novasit-bm;moertelprodukte">' +
                        '<div class="content__item-inner">' +
                            '<a class="content-inner-item-name" href="' + url + '" download="' + filename + '">' +
                                '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_517_3038)"><path d="M27 19H23V26" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M26 23H23" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 24H8C8.66304 24 9.29893 23.7366 9.76777 23.2678C10.2366 22.7989 10.5 22.163 10.5 21.5C10.5 20.837 10.2366 20.2011 9.76777 19.7322C9.29893 19.2634 8.66304 19 8 19H6V26" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 19V26H16C16.9283 26 17.8185 25.6313 18.4749 24.9749C19.1313 24.3185 19.5 23.4283 19.5 22.5C19.5 21.5717 19.1313 20.6815 18.4749 20.0251C17.8185 19.3687 16.9283 19 16 19H14Z" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 14V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H19L26 11V14" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 4V11H26" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_517_3038"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>' +
                                '<p>' + name + '</p>' +
                            '</a>' +
                            '<div class="content-inner-item-download">' +
                                '<a href="' + url + '" download="' + filename + '">' +
                                    '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_517_3049)"><path d="M15 17.8125V4.6875" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M25.3125 17.8125V24.375C25.3125 24.6236 25.2137 24.8621 25.0379 25.0379C24.8621 25.2137 24.6236 25.3125 24.375 25.3125H5.625C5.37636 25.3125 5.1379 25.2137 4.96209 25.0379C4.78627 24.8621 4.6875 24.6236 4.6875 24.375V17.8125" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.6875 13.125L15 17.8125L10.3125 13.125" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_517_3049"><rect width="30" height="30" fill="white"/></clipPath></defs></svg>' +
                                '</a>' +
                                '<a class="add-to-cart" href="#" data-name="' + name + '" data-url="' + url + '">' +
                                    '<svg class="add" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_929_691)"><path d="M4 26V8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11.6663C11.8826 7 12.0932 7.07018 12.2662 7.2L15.7338 9.8C15.9068 9.92982 16.1174 10 16.3337 10H25C25.2652 10 25.5196 10.1054 25.7071 10.2929C25.8946 10.4804 26 10.7348 26 11V14" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 26L7.7725 14.6838C7.83888 14.4846 7.96623 14.3114 8.13651 14.1887C8.30678 14.066 8.51136 14 8.72125 14H29C29.1585 14 29.3147 14.0376 29.4557 14.1098C29.5968 14.182 29.7186 14.2867 29.8113 14.4153C29.904 14.5438 29.9647 14.6925 29.9886 14.8492C30.0125 15.0058 29.9989 15.1659 29.9487 15.3162L26.3862 26H4Z" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><path d="M16.5916 23.4876V16.9506H18.2443V23.4876H16.5916ZM14.1495 21.0455V19.3928H20.6864V21.0455H14.1495Z" fill="#E53434"/><defs><clipPath id="clip0_929_691"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>' +
                                    '<svg class="remove" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_908_1216)"><path d="M27 7H5" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 13V21" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 13V21" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M25 7V26C25 26.2652 24.8946 26.5196 24.7071 26.7071C24.5196 26.8946 24.2652 27 24 27H8C7.73478 27 7.48043 26.8946 7.29289 26.7071C7.10536 26.5196 7 26.2652 7 26V7" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 7V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H13C12.4696 3 11.9609 3.21071 11.5858 3.58579C11.2107 3.96086 11 4.46957 11 5V7" stroke="#E53434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>' +
                                '</a>' +
                            '</div>' +
                        '</div>' +
                    '</div>';

        $('#download-content').append(item);
    }
});