jQuery(document).ready(function($){
    $('#home-slider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        pauseOnHover: true,
        pauseOnFocus: true,

        customPaging : function(slider, i) {
            return '<div class="slick-dot"></div>';
        }

    });

    // slick on .flamro-historie
    $('.flamro-historie').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        pauseOnHover: true,
        pauseOnFocus: true,
        slidesToShow: 4,
        customPaging : function(slider, i) {
            return '<div class="slick-dot"></div>';
        },
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '10%',
                    centerMode: true,
                },
            }
        ]
    });

    // slick on .flamro-imagebox-container element only on mobile
    // on window resize, destroy and reinit slick
    function slickOnMobile() {
        if ($(window).width() < 768) {
            $('.flamro-imagebox-container').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: false,
                dots: true,
                pauseOnHover: true,
                pauseOnFocus: true,
                slidesToShow: 1,
                centerPadding: '10%',
                centerMode: true,
                customPaging : function(slider, i) {
                    return '<div class="slick-dot"></div>';
                }
            });
        } else {
            // only destroy slick if it's initialized already
            if ($('.flamro-imagebox-container').hasClass('slick-initialized')) {
                $('.flamro-imagebox-container').slick('unslick');
            }
        }
    }

    slickOnMobile();

    $(window).resize(function () {
        slickOnMobile();
    }
    );


    // $('.flamro-imagebox-container').slick({
    //     autoplay: true,
    //     autoplaySpeed: 5000,
    //     arrows: false,
    //     dots: true,
    //     pauseOnHover: true,
    //     pauseOnFocus: true,
    //     slidesToShow: 4,
    //     responsive: [
    //         {
    //             breakpoint: 1000,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 centerMode: true,
    //                 centerPadding: '10%',

    //             }
    //         }
    //     ],
    //     customPaging : function(slider, i) {
    //         return '<div class="slick-dot"></div>';
    //     }
    // });
});