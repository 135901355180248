const toggleButtons = document.querySelectorAll("[data-toggle]");

toggleButtons.forEach((toggle) => {
  const dataToggle = toggle.getAttribute("data-toggle");
  const targets = document.getElementsByClassName(dataToggle);
  const topSpace = document.querySelector("#header-outer").clientHeight;

  for (let i = 0; i < targets.length; i++) {
    if (window.innerWidth < 700) {
      targets[i].style.paddingTop = `${topSpace}px`;
    }
  }

  toggle.addEventListener("click", () => {
    const topSpace_ = document.querySelector("#header-outer").clientHeight;

    for (let i = 0; i < targets.length; i++) {
      targets[i].classList.toggle("active");
      targets[i].style.paddingTop = `${topSpace_}px`;
      document.body.classList.toggle("noscroll");
    }
  });
});