$(".flamro-product-toggles__item h3").click(function(){
  $(this).next().slideToggle("fast");
  $(this).toggleClass("active");
  $(this).find("svg").toggleClass("active");
});

$(".flamro-toggles__item h3").click(function(){
  $(this).next().slideToggle("fast");
  $(this).toggleClass("active");
  $(this).find("svg").toggleClass("active");
});


// Filter
// $(".filter-inner-category-filter .toggle-trigger").append('<svg class="opener" width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.255 1.01416L8.00504 6.95166C7.94699 7.00686 7.87806 7.05066 7.80219 7.08054C7.72631 7.11042 7.64498 7.1258 7.56285 7.1258C7.48071 7.1258 7.39938 7.11042 7.32351 7.08054C7.24764 7.05066 7.17871 7.00686 7.12066 6.95166L0.87066 1.01416C0.753385 0.902747 0.6875 0.75164 0.6875 0.59408C0.6875 0.43652 0.753385 0.285413 0.87066 0.174002C0.987935 0.0625901 1.147 0 1.31285 0C1.4787 0 1.63776 0.0625901 1.75503 0.174002L7.56285 5.69217L13.3707 0.174002C13.4287 0.118836 13.4977 0.0750767 13.5735 0.0452214C13.6494 0.0153661 13.7307 0 13.8128 0C13.895 0 13.9763 0.0153661 14.0522 0.0452214C14.128 0.0750767 14.197 0.118836 14.255 0.174002C14.3131 0.229167 14.3592 0.294658 14.3906 0.366735C14.422 0.438813 14.4382 0.516064 14.4382 0.59408C14.4382 0.672096 14.422 0.749348 14.3906 0.821425C14.3592 0.893502 14.3131 0.958993 14.255 1.01416Z" fill="#2D2D2D"/></svg>');

// For Checkbox
// $(".filter-inner-category-filter .toggle-trigger label").click(function(){
//   $(this).parent().next().slideToggle("fast");
//   // find the svg inside the .toggle-trigger and toggle the class
//   $(this).parent().find("svg").toggleClass("active");
// });

// // For <p> tag
// $(".filter-inner-category-filter p.toggle-trigger").click(function(){
//   $(this).next().slideToggle("fast");
//   $(this).find("svg").toggleClass("active");
// });