jQuery(document).ready(function($) {
    // on click of #produkt-anfrage, show #anfrage-layer
    $('#produkt-anfrage').click(function() {
        event.preventDefault();
        $('#anfrage-layer').show();
    });
    // on click of #anfrage-layer, hide #anfrage-layer
    $('#anfrage-layer').click(function() {
        $('#anfrage-layer').hide();
    });
    // on click of #anfrage-layer-content, prevent hiding of #anfrage-layer
    $('.anfrage-layer__content').click(function(e) {
        e.stopPropagation();
    });
    // on click of #anfrage-layer-close, hide #anfrage-layer
    $('.anfrage-layer__close').click(function() {
        $('#anfrage-layer').hide();
    });

});